<template>
  <div id="selection_container">
    <img :src="require('../assets/landing_resized.jpg')" />
  </div>
</template>

<script>
export default {
  name: "Landing"
};
</script>
<style scoped>
#selection_container {
  padding: 3px;
  margin: 3px;
}
.icon {
  font-size: 100px;
}
.text_box {
  height: 100px;
}
.box_option {
  padding: 20px 5px;
  border-radius: 10px;
  font-family: Verdana, sans-serif;
}
.container {
  height: 250px;
}
h4 {
  font-size: 27px;
  margin-bottom: 20px;
}
</style>
