<template>
  <a-layout>
    <a-layout-sider :collapsed="true">
      <a-menu
        :openKeys="this.$props.openKeys"
        :selectedKeys="
          this.$props.selectedCustomer
            ? [this.$props.selectedCustomer.name]
            : []
        "
        @openChange="onOpenChange"
        mode="inline"
        theme="dark"
        :inline-collapsed="true"
      >
        <a-sub-menu key="customers">
          <template #title>
            <span><AreaChartOutlined /><span>Reports</span></span>
          </template>
          <a-menu-item
            style="padding-right: 60px"
            class="customers"
            @click="handleSelect"
            v-for="customer in customers"
            :key="customer.name"
          >
            <UserOutlined />
            {{ customer.name }}
          </a-menu-item>
        </a-sub-menu>
        <a-menu-item disabled>
          <PieChartOutlined />
          <span>Option 1</span>
        </a-menu-item>
        <a-menu-item disabled>
          <DesktopOutlined />
          <span>Option 2</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout-content style="margin: 0 16px;">
      <router-view v-if="selectedCustomer" :url="selectedCustomer.reportUrl" />
      <router-view v-else />
    </a-layout-content>
  </a-layout>
</template>
<script>
import {
  AreaChartOutlined,
  PieChartOutlined,
  DesktopOutlined,
  UserOutlined
} from "@ant-design/icons-vue";

export default {
  components: {
    AreaChartOutlined,
    PieChartOutlined,
    DesktopOutlined,
    UserOutlined
  },
  props: ["customers", "selectedCustomer", "openKeys", "preOpenKeys"],
  data() {
    return {
      rootSubmenuKeys: ["customers"]
    };
  },
  methods: {
    onOpenChange(openKeys) {
      this.$emit("OpenChange", openKeys);
    },
    handleSelect(selectedObj) {
      this.$emit("KeyChange", selectedObj.key);
    }
  }
};
</script>
