export const firebaseConfig = {
  apiKey: "AIzaSyCAbC7mJQEYsXH2uzO72h6DKypMOBNYnq4",
  authDomain: "seeloz-production.firebaseapp.com",
  projectId: "seeloz-production",
  storageBucket: "seeloz-production.appspot.com",
  messagingSenderId: "1009706091161",
  appId: "1:1009706091161:web:1a5725edfc07ffa2bc2e08"
};
export const gAuthClientId =
  "1009706091161-ht5185snckmls4cmf85qnaci0j697mi1.apps.googleusercontent.com";
