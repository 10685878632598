const apiEndpoint =
  "https://us-central1-seeloz-production.cloudfunctions.net/dashboards/api";

export const fetchCustomers = async appUser => {
  const userToken = await appUser.getIdToken();
  try {
    const response = await fetch(`${apiEndpoint}/reports`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json"
      }
    });
    if (response.status === 403)
      return { err: "Access Denied, Invalid User.", customers: null };
    if (response.status === 500) {
      const { error } = await response.json();
      throw new Error(error);
    }
    if (response.ok) {
      const { customers } = await response.json();
      return { customers, err: null };
    }
  } catch (err) {
    return { customers: null, err };
  }
};

export const authenticateUser = async googleUser => {
  try {
    const userPayload = { token: googleUser.getAuthResponse().id_token };
    const response = await fetch(`${apiEndpoint}/auth`, {
      method: "POST",
      body: JSON.stringify(userPayload),
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (response.status === 403)
      return { err: "Access Denied, Invalid User.", customers: null };
    if (response.status === 500) {
      const { error } = await response.json();
      throw new Error(error);
    }
    if (response.ok) {
      const { token } = await response.json();
      return { userToken: token, err: null };
    }
  } catch (err) {
    return { userToken: null, err };
  }
};
