<template>
  <div class="home">
    <a-layout style="min-height: 100vh">
      <a-layout-header style="background: #001529; display: inherit">
        <div class="logo">
          <a @click="handleLogoClick" class="fill-div"></a>
        </div>
        <a-menu mode="horizontal" theme="dark" class="flex-setting">
          <a-sub-menu v-if="user">
            <template #title>
              <span class="submenu-title-wrapper">
                <UserOutlined />
                {{ user.email }}
              </span>
            </template>

            <a-menu-item
              ><a @click="handleLogout" target="_self">Logout</a>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-header>
      <admin-dashboard
        :customers="customers"
        :selectedCustomer="selectedCustomer"
        @KeyChange="handleKeyChange"
        @OpenChange="handleOpenChange"
      />
      <div class="footerholder">
        <div class="footer">SeelozInc ©2020</div>
      </div>
    </a-layout>
  </div>
</template>

<script>
import { UserOutlined } from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import AdminView from "./Admin.vue";
import { auth } from "../firebase";
import { defineAsyncComponent } from "vue";
import { fetchCustomers } from "../api";

export default {
  components: {
    UserOutlined,
    AdminDashboard: defineAsyncComponent(() => Promise.resolve(AdminView))
  },
  data() {
    return {
      isAdmin: false,
      isUser: false,
      customers: [],
      user: null,
      selectedCustomer: null,
      openKeys: [],
      preOpenKeys: []
    };
  },
  async created() {
    try {
      const userToken = await auth.currentUser.getIdTokenResult();
      const role = userToken.claims.role;
      if (role === "user") this.isUser = true;
      else this.isAdmin = true;
      const { customers, err } = await fetchCustomers(auth.currentUser);
      if (err) throw new Error(err);
      this.customers = customers;
      this.user = auth.currentUser;
      this.validateRoutes(this.$route);
    } catch (err) {
      console.error(err);
      this.openNotificationWithIcon("error", err.message ? err.message : err);
    }
  },
  watch: {
    $route(to) {
      this.validateRoutes(to);
    }
  },
  methods: {
    clear() {
      this.selectedCustomer = null;
      this.openKeys = [];
      this.preOpenKeys = [];
    },
    handleLogoClick() {
      this.clear();
      this.$router.push("/");
    },
    handleKeyChange(selectedKey) {
      this.$router.push(`/${selectedKey}`);
      this.selectedCustomer = this.customers.find(c => c.name === selectedKey);
    },
    handleOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        key => this.openKeys.indexOf(key) === -1
      );
      if (this.customers.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    validateRoutes(to) {
      const pathCustomer = this.customers.find(c => to.path.includes(c.name));
      if (pathCustomer) {
        const invalid = to.path.split(pathCustomer.name)[1] !== "";
        if (invalid) {
          this.clear();
          this.$router.push("/404");
        } else this.selectedCustomer = pathCustomer;
      } else {
        const validPaths = this.$router.options.routes
          .map(route => route.path)
          .shift();
        if (!validPaths.includes(to.path)) {
          this.clear();
          this.$router.push("/404");
        }
      }
    },
    async handleLogout() {
      try {
        await auth.signOut();
        this.$router.push("/auth");
      } catch (err) {
        console.log(err.message);
      }
    },
    openNotificationWithIcon(type, message) {
      notification[type]({
        message: "Error",
        description: message
      });
    }
  }
};
</script>
<style scoped>
.logo {
  height: 180px;
  width: 180px;
  margin: -42px -30px;
  background-image: url("../assets/seeloz.png");
}

a.fill-div {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
}

.flex-setting {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding-top: 9px;
  margin-right: -25px;
}

.footerholder {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  position: fixed;
  text-align: center;
  width: 100%;
}

.footer {
  background: none repeat scroll 0 0;
  height: 22px;
  margin: auto;
  width: 400px;
  text-align: "center";
}

@media only screen and (max-width: 800px) {
  .logo {
    background: url("../assets/seeloz_small.png") no-repeat center;
    width: 106px;
    height: 150px;
    margin: -45px 0px;
  }
}
</style>
