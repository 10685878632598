<template>
  <iframe
    height="95%"
    width="100%"
    frameborder="0"
    v-if="url"
    :src="url"
    allowfullscreen
    scrolling="no"
    style="padding: 0px; padding-top: 10px; margin: 0px;"
  ></iframe>
  <!-- <a-layout-content v-if="isUser">
    <iframe
      height="87%"
      width="100%"
      frameborder="0"
      scrolling="no"
      v-if="url"
      :src="url"
      allowfullscreen
      style="position: fixed; border: 0; padding: 5px; margin: 2px;left: 0px;"
    ></iframe> -->
  <!-- </a-layout-content> -->
</template>
<script>
export default {
  name: "Report",
  props: ["url"]
};
</script>
