import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../components/Login.vue";
import Landing from "../components/Landing.vue";
import Report from "../components/Report.vue";
import NotFound from "../components/NotFound.vue";
import { auth } from "../firebase";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        component: Landing,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/:user",
        props: { default: true },
        component: Report,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/auth",
    component: Login,
    name: "Login",
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/404",
    component: NotFound,
    name: "NotFound",
    meta: {
      requiresAuth: true
    }
  },
  { path: "/:catchAll(.*)", redirect: "/404" }
];

const router = new createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !(await auth.getCurrentFirebaseUser())) {
    next("/auth");
  } else next();
});

export default router;
