import "@babel/polyfill";
import "mutationobserver-shim";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "mdbvue/lib/css/mdb.min.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { antdInjector, gAuthInjector } from "./injectors";

const app = createApp(App);
app.use(router);
antdInjector(app);
gAuthInjector(app);
app.mount("#app");
