<template>
  <div class="login-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
          <div class="card login" v-bind:class="{ error: emptyFields }">
            <h1>Seeloz Dashboards</h1>
            <p class="err-msg" v-if="isError">{{ error }}</p>
            <form class="form-group">
              <br />
              <button
                @click.prevent="handleClickSignIn"
                :disabled="!Vue3GoogleOauth.isInit"
                class="btn btn-gplus google-login"
              >
                <i class="fab fa-google-plus-g pr-1"></i>via Google +
              </button>
              <br />
              <br v-if="isLoading" />
              <div
                v-if="isLoading"
                class="spinner-border text-primary"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, firebase } from "../firebase";
import { authenticateUser } from "../api";
import { inject, toRefs } from "vue";

export default {
  name: "Login",
  data() {
    return {
      isLoading: false,
      email: "",
      password: "",
      emptyFields: false,
      error: "",
      isError: false
    };
  },
  created() {
    auth.onAuthStateChanged(user => {
      if (!user)
        auth
          .signOut()
          .then(() => this.$router.push("/auth"))
          .catch(err => console.error(err));
    });
  },
  setup(props) {
    const { isSignIn } = toRefs(props);
    const Vue3GoogleOauth = inject("Vue3GoogleOauth");
    const handleClickLogin = () => {};
    return {
      Vue3GoogleOauth,
      handleClickLogin,
      isSignIn
    };
  },
  methods: {
    setErr(err) {
      this.error = err;
      this.isError = true;
      if (this.isLoading) this.isLoading = false;
    },
    isUserEqual(googleUser, firebaseUser) {
      if (firebaseUser) {
        var providerData = firebaseUser.providerData;
        for (var i = 0; i < providerData.length; i++) {
          if (
            providerData[i].providerId ===
              firebase.auth.GoogleAuthProvider.PROVIDER_ID &&
            providerData[i].uid === googleUser.getBasicProfile().getId()
          ) {
            return true;
          }
        }
      }
      return false;
    },
    async handleClickSignIn() {
      this.isLoading = true;
      this.err = "";
      this.isError = false;
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) return this.setErr("Unable to fetch google user.");
        const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
          try {
            unsubscribe();
            if (!this.isUserEqual(googleUser, firebaseUser)) {
              const { userToken, err } = await authenticateUser(googleUser);
              if (err) throw new Error(err);
              await auth.signInWithCustomToken(userToken);
              this.isLoading = false;
              this.$router.push("/");
            } else {
              console.log("User already signed-in Firebase.");
            }
          } catch (err) {
            console.error(err);
            this.setErr(err.message ? err.message : err);
          }
        });
      } catch (err) {
        console.error(err);
        if (err.error === "popup_closed_by_user")
          return this.setErr("Popup closed before authentication.");
        this.setErr(err.message ? err.message : err);
      }
    }
  }
};
</script>

<style scoped>
h1 {
  color: rgba(255, 255, 255, 0.924);
}

p {
  line-height: 1rem;
}

.card {
  padding: 20px;
}

.login {
  background-color: rgba(17, 23, 37, 0.616);
}

.form-group input {
  margin-bottom: 20px;
}

.login-page {
  align-items: center;
  display: flex;
  background: url("https://images.idgesg.net/images/article/2018/02/big_data_analytics_analysis_statistics_thinkstock_626673360-100749740-large.jpg")
    no-repeat center center;
  background-size: cover;
  height: 100vh;
  position: absolute;
  background-size: 100% 100%;
  width: 100%;
}

.login-page .fade-enter-active,
.login-page .fade-leave-active {
  transition: opacity 0.5s;
}

.login-page .fade-enter,
.login-page .fade-leave-to {
  opacity: 0;
}

.login-page h1 {
  margin-bottom: 1.5rem;
}

.google-login {
  color: #fff;
  background: #dd4b39 !important;
  width: 80%;
}

.error {
  animation-name: errorShake;
  animation-duration: 0.3s;
}

.err-msg {
  color: red;
  font-size: 12px;
}

@keyframes errorShake {
  0% {
    transform: translateX(-25px);
  }
  25% {
    transform: translateX(25px);
  }
  50% {
    transform: translateX(-25px);
  }
  75% {
    transform: translateX(25px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
